<template> 
    <CRow class="mt-5 row justify-content-md-center" >
         <CCol md="3"><div class="ml-2"><h4><strong >問題回報</strong></h4><h6>請寫下您的問題或寶貴的建議，讓我們提供更好的服務</h6></div></CCol>
         <CCol md="8">
             <CCard>
                <CCardBody class="p-4">
                 <div v-if="isSend" class="alert alert-success" role="alert"> 
                        <h4 class=" mt-5 mb-5 alert-heading">感謝您!</h4>
                        <p class=" h5 mt-5 mb-5">我們將詳閱您提供的資訊，並且盡快處理，感謝您的耐性等候</p> 
                 </div> 
                 <div v-else> 
                    <form @submit.prevent="sendFeedback"> 
                    <div class="text-center mt-3 mb-3">
                             <loading :active.sync="isLoading" 
                                :can-cancel="false" 
                                :is-full-page="true"></loading>
                    </div>
                    <div class="row"> 
                        <div class="col-sm-12"> 
                            <div class="form-group text-center">
                                <label><h5>服務評分</h5></label>
                                <div class="custom-rating">
                                   <star-rating :increment="1" :star-size="25"   :rounded-corners="true" :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"  @rating-selected="setRating"></star-rating> 
                                </div> 
                            </div>
                            <div class="form-group mt-5 ">
                                 <label for="selected"><h5>回報類型</h5></label>
                                   <select id="selected" class="form-control" v-model="selected">
                                    <option data-key="0" value="Problem"> 問題 </option>
                                    <option data-key="1" value="Like"> 我喜歡 ... </option>
                                    <option data-key="2" value="Missing"> 可以增加 ... </option>
                                  </select>
                            </div>
                            <div class="form-group mt-3">
                                <label for="memo"><h5>內容說明</h5></label>
                                <CTextarea v-model="memo" placeholder="內容說明...." rows="5"></CTextarea> 
                            </div> 
                            <div class="form-group mt-3">
                                <label for="file"><h5>截圖上傳</h5></label><br>
                                <div class="custom-file"> 
                                    <input type="file" class="custom-file-input" lang="zn" id="file" ref="file" v-on:change="handleFileUpload()">
                                    <label class="custom-file-label" for="file">選擇文件</label>
                                </div>
                                <span class="text-success">{{filelabel}}</span>
                            </div> 
                            <div class="text-center mt-4">
                                 <button type="submit" class="btn btn-block btn-success"><strong class="text-white">送出</strong></button>  
                            </div>
                        </div>
                    </div>   
                </form>
                 </div> 

               
            </CCardBody>
             </CCard>
         </CCol>
    </CRow>
   
</template>

<script>
    import axios from 'axios';
    import appSettings from '../../util/settings';
    import store from '../../util/store';
    import StarRating from 'vue-star-rating'
    import Loading from 'vue-loading-overlay'; 
    export default {
        components: {
            StarRating,
            Loading
        },
         data() {
            return {
                selected:'Problem',
                memo:'',
                attach:undefined,
                selectedRating:0,
                filelabel:'',
                isSend:false,
                isLoading:false,
            }
        },
        methods: { 
            handleFileUpload(){
                this.attach = this.$refs.file.files[0];

                //console.log(this.attach.name);  
                this.filelabel= this.attach.name;
            },
            uploadResult(response){
                var data = response.data;
                if(data.IsLogin){ 
                   this.isSend=true; 
                }
                else{
                    store.commit("clearUser");
				    this.$router.replace({ name: "Login" });
                }
            },
            sendFeedback() {
                this.isLoading = true; 
                let formData = new FormData();
                formData.append('uploadFile', this.attach);
                formData.append('type', this.selected);
                formData.append('memo', this.memo); 
                formData.append('rating',this.selectedRating);
                formData.append('idToken', store.state.token);
                axios.post(appSettings.Feedback, 
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                         }
                    })
                    .then(this.uploadResult)
                    .catch(function (error) { 
                        //dialog.showInforDialog(error);
                        console.log(error);
                    }) 
                    .finally(() => {
                        this.isLoading = false; 
                    });    
            },
            setRating: function(rating) {
                this.selectedRating = rating ;
            }, 
        } 
    }
</script>